<template>
  <div
    class="headline"
    :class="[
      headlineSize,
      'align--' + alignHeadline,
      verticalMargin ? verticalMargin : '',
    ]"
    :style="[headlineColorCss, headlineAccentColorCss]"
  >
    <div v-if="eyebrow && eyebrow.html" class="eyebrow" v-html="eyebrow.html" />
    <component
      :is="headlineType"
      v-if="headline && headline.html"
      class="headline-text"
      :class="{ 'clear-margin': !lead || !lead.html }"
      v-html="rmvOl(headline.html)"
    />
    <div v-if="lead && lead.html" class="lead" v-html="lead.html" />
  </div>
</template>
<script>
import Headline from '@made-people/centra-storyblok-nuxt-shared/lib/components/headline/Headline'
export default {
  ...Headline,
  props: {
    ...Headline.props,
    verticalMargin: {
      type: String,
      required: false,
      default: 'margin-top-bottom',
    },
  },
  computed: {
    ...Headline.computed,
    headlineColorCss() {
      return {
        '--headline-color':
          this.headlineColorHex || `var(--${this.headlineColor})` || '#000000',
      }
    },
  },
  methods: {
    rmvOl(str) {
      // Cast to string because it's a number on the error page
      let output = `${str}`
      let num

      // grab starting <ol> index
      const numIndex = output.indexOf('start="')
      if (numIndex > -1) {
        num = output.substring(numIndex + 7)
        const split = num.split('"')
        num = split[0]
      } else if (output.includes('<ol')) num = 1

      output = output.replace(/<li>*>/g, '')
      output = output.replace(/<\/li>*>/g, '')
      output = output.replace(/<ul>*>/g, '')
      output = output.replace(/<\/ul>*>/g, '')
      output = output.replace(/<ol[^>]*>/g, '')
      output = output.replace(/<\/ol>*>/g, '')

      return num ? `${num}. ${output}` : output
    },
  },
}
</script>
<style lang="scss" scoped>
.headline {
  position: relative;
  color: var(--headline-color, $black);

  &::v-deep {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      > p {
        margin: 0;
        a {
          text-decoration: none;
        }
      }
    }
  }

  &.align--left {
    text-align: left;
  }
  &.align--center {
    text-align: center;
  }
  &.align--right {
    text-align: right;
  }
  &.margin-bottom {
    margin-top: 0 !important;
  }
  &.margin-top {
    margin-bottom: 0 !important;
  }
  &.margin-none {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .eyebrow {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: auto;
    @include caps('small');
    &::v-deep {
      p {
        margin: 0;
      }
    }
  }

  .lead {
    @include p--medium;

    &::v-deep {
      p {
        margin-bottom: 0;
      }
    }
  }
}
</style>
